import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Getting Started on NodeJS",
  "date": "2014-07-23T02:07:52-05:00",
  "tags": ["nodejs", "express"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`###What is NodeJS?
NodeJS is a server side, event-driven, non-blocking IO JavaScript platform that helps create lightweight, fast and efficient applications`}</p>
    <p>{`###What can I do with NodeJS?
Virtually anything! For example:`}</p>
    <ul>
      <li parentName="ul">{`DNS server `}<a parentName="li" {...{
          "href": "https://github.com/tjfontaine/node-dns"
        }}>{`https://github.com/tjfontaine/node-dns`}</a></li>
      <li parentName="ul">{`Chat server `}<a parentName="li" {...{
          "href": "http://code.tutsplus.com/tutorials/using-nodejs-and-websockets-to-build-a-chat-service--net-34482"
        }}>{`http://code.tutsplus.com/tutorials/using-nodejs-and-websockets-to-build-a-chat-service—net-34482`}</a></li>
      <li parentName="ul">{`Web application - This blog is built on `}<a parentName="li" {...{
          "href": "https://ghost.org/"
        }}>{`Ghost`}</a>{` which runs on NodeJS!`}</li>
      <li parentName="ul">{`CSS preprocessor / JavaScript minification (e.g. `}<a parentName="li" {...{
          "href": "http://gruntjs.com/"
        }}>{`Grunt`}</a>{`, `}<a parentName="li" {...{
          "href": "http://bower.io/"
        }}>{`Bower`}</a>{`, `}<a parentName="li" {...{
          "href": "http://getbootstrap.com/"
        }}>{`Bootstrap`}</a>{`, etc)`}</li>
    </ul>
    <p>{`###How do I get start?
Make sure that you have NodeJS installed, you can download it from `}<a parentName="p" {...{
        "href": "http://nodejs.org."
      }}>{`http://nodejs.org.`}</a>{` Once you can it installed, check that you can run it from your command prompt / terminal via the following command:`}</p>
    <pre><code parentName="pre" {...{}}>{`    node --version
`}</code></pre>
    <p>{`There are two important files/directories we need to know about:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`package.json`}</strong>{` file`}</li>
      <li parentName="ol"><strong parentName="li">{`node_modules`}</strong>{` directory`}</li>
    </ol>
    <p>{`###package.json
The package.json file stores metadata/information about your project. It typically has the following content`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "name": "project-name",
  "version": "1.0.0",
  "description": "Project description",
  "author": "Your Name <your@email.com>"
  "private": true,
  "main": "main.js",
  "dependencies": {}
}
`}</code></pre>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Name`}</strong>{` - the name of your project (required)`}</li>
      <li parentName="ul"><strong parentName="li">{`version`}</strong>{` - the version of your project (required)`}</li>
      <li parentName="ul"><strong parentName="li">{`description`}</strong>{` - additional information about your project (optional)`}</li>
      <li parentName="ul"><strong parentName="li">{`author`}</strong>{` - author of this project, typically in `}<inlineCode parentName="li">{`author <email>`}</inlineCode>{` pair, you can also use `}<inlineCode parentName="li">{`{"name":"Your Name", "email":"your@email.com"}`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`private`}</strong>{` - we do not want to publish this project to npm registry.`}</li>
      <li parentName="ul"><strong parentName="li">{`main`}</strong>{` - the main entry point of your project. You can create a .js file with any name as your entry point and put the file name here.`}</li>
      <li parentName="ul"><strong parentName="li">{`dependencies`}</strong>{` - dependencies or libraries that the project is going to use.`}</li>
    </ul>
    <p>{`For more information about package.json file, visit `}<a parentName="p" {...{
        "href": "http://browsenpm.org/package.json"
      }}>{`http://browsenpm.org/package.json`}</a></p>
    <p>{`###node_modules
The node_modules stores downloaded project dependencies for your project. It can be project specific dependencies, which only usable in the same project, or global dependencies, which can be used in multiple projects.`}</p>
    <p>{`The dependencies are usually published and downloaded from a registry. The default is `}<a parentName="p" {...{
        "href": "https://www.npmjs.org"
      }}>{`https://www.npmjs.org`}</a></p>
    <p>{`##Creating a Web Application Project
Create an empty directory for your project, you can name it anything you want, let’s call it `}<strong parentName="p">{`MyProject`}</strong>{` for now. Enter into the directory and create a file called `}<strong parentName="p">{`package.json`}</strong>{` and another file called `}<strong parentName="p">{`main.js`}</strong>{`. The package.json file describes information about your project and is very important especially if your project depends on third party modules.`}</p>
    <p>{`In your package.json file add the following content`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "name": "myproject",
  "version": "1.0.0",
  "description": "NodeJS example",
  "author": "Your Name <your@email.com>"
  "private": true,
  "main": "main.js",
  "dependencies": {}
}
`}</code></pre>
    <p>{`Next, in your command prompt / terminal, execute the following command in `}<strong parentName="p">{`MyProject`}</strong>{` directory:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install express --save
`}</code></pre>
    <p>{`This will download and install a dependencies called `}<strong parentName="p">{`express`}</strong>{`, which is a light weight web application framework, and stored in node_modules folder. For more information about `}<strong parentName="p">{`express`}</strong>{`, visit `}<a parentName="p" {...{
        "href": "http://expressjs.com/"
      }}>{`http://expressjs.com/`}</a></p>
    <p>{`The command above will also append the dependency in the package.json file. You can also specify the dependency manually in the package.json file and execute just the `}<inlineCode parentName="p">{`npm install`}</inlineCode>{` part to install the dependency.`}</p>
    <p>{`Next, we will write our main application code in the `}<strong parentName="p">{`main.js`}</strong>{` file. Add the following content to the `}<strong parentName="p">{`main.js`}</strong>{` file:`}</p>
    <pre><code parentName="pre" {...{}}>{`var express = require('express');
var app = express();

app.get('/',function(req,res){
    res.send('hello world');
});

app.listen(3000);
`}</code></pre>
    <p>{`Let’s explain the code above:`}</p>
    <p><inlineCode parentName="p">{`var express = require('express');`}</inlineCode>{` means that we import our dependencies, which in this case is `}<em parentName="p">{`express`}</em></p>
    <p><inlineCode parentName="p">{`var app = express()`}</inlineCode>{` means that we create our Express based application in a variable call `}<em parentName="p">{`app`}</em></p>
    <p><inlineCode parentName="p">{`app.get('/hello',function(req,res){ res.send('hello world'); }); `}</inlineCode>{`
means that when we visit http: / /`}{`<`}{`hostname or localhost`}{`>`}{`:`}{`<`}{`port`}{`>`}<strong parentName="p">{`/hello`}</strong>{` it will print `}<inlineCode parentName="p">{`hello world`}</inlineCode></p>
    <p><inlineCode parentName="p">{` app.listen(3000);`}</inlineCode>{` means that we run the application and listen to port 3000. You can also specify the hostname as second argument, such as `}<inlineCode parentName="p">{`app.listen(3000,'example.com')`}</inlineCode></p>
    <p>{`Lastly, executed the following command and visit http://localhost:3000/hello from your browser and see your application in action!`}</p>
    <p>{`###In Summary
We have learnt what NodeJS is, what it is capable of, some of the important files/directories and how to create a NodeJS based application with Express framework.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      